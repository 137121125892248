import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Page/LoginPage.js';
import RegisterPage from './Page/RegisterPage.js';
import UserPage from './Page/UserPage.js';
import RecoverPage from './Page/RecoverPage.js';
import ChangePasswordPage from './Page/ChangePasswordPage.js';
import ChangePinPage from './Page/ChangePinPage.js';
import DownloadPage from './Page/DownloadPage.js';
import UserListPage from './Page/UserListPage.js';
import ResponsiveMenu from './components/ResponsiveMenu'; 
import { GlobalProvider } from './connection/GlobalContext';
import { Provider } from 'react-redux';
import store from './redux/store'; 
import discordIcon from './img/discord.png';
import twitchIcon from './img/twitch.png';
import instagramIcon from './img/instagram.png';
import facebookIcon from './img/facebook.png';
import youtubeIcon from './img/youtube.png';
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css';

function App() {
  const discordUrl = 'https://discord.com/servers/desterium-game-700452646326239252';
  const twitchUrl = 'https://www.twitch.tv/DesteriumGame';
  const instagramUrl = 'https://www.instagram.com/DesteriumGame';
  const facebookUrl = 'https://www.facebook.com/DesteriumGame';
  const youtubeUrl = 'https://www.youtube.com/@desteriumgame';

  const [showDownloads, setShowDownloads] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [showServerInfo, setShowServerInfo] = useState(false);
  const [shuffledImages, setShuffledImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [serverRates, setServerRates] = useState({});
  const [gamesData, setGamesData] = useState({});
  const [imagesLoaded, setImagesLoaded] = useState({});

  // Función para cargar los rates de los servidores desde la API
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await fetch('/api/rates');
        const data = await response.json();
        const ratesMap = {};

        // Mapear los datos obtenidos a los juegos existentes
        data.forEach(server => {
          ratesMap[server.id] = {
            title: server.servername,
            exp: `Exp x${server.rates_exp}`,
            oro: `Oro x${server.rates_gld}`,
            drop: `Drop x${server.rates_drop}`,
            reset: server.rates_reset ? `Reset cada ${server.rates_reset} meses` : 'NO RESET',
            onlines: server.rates_online,
          };
        });

        setServerRates(ratesMap);
        setGamesData({
          agite: {
            number: 1,
            title: "Desterium Agite",
            images: generateImages('/img/facil', 31),
            subtitle: "Servidor MMORPG 2D basado Argentum Online y Tierras del Sur.",
            sharedDownloadWith: 'Desterium Hardcore',
            details: [
              { text: "Ver estadísticas de personajes", link: "/user-list?server=1&servername=Desterium%20Facil" },
              { text: "Ver código en GitHub", link: "https://github.com/LautaroMarino/DesteriumGame" }
            ],
            downloads: [
              { label: "Opción 1 - DESCARGA DIRECTA", url: "https://www.desterium.net/file/DesteriumV8.exe" },
              { label: "Opción 2 - vía MEDIAFIRE", url: "https://www.mediafire.com/file/jg3e4qlja0jq3ez/DesteriumV8.exe/file" },
              { label: "Opción 3 - vía MEGA", url: "https://mega.nz/file/HBJUULxS#e3kGmiTh0tp4LrQfqxRB5MozHTGWFcKjzSHwXRtEO9M" },
              { label: "Opción 4 - vía DRIVE", url: "https://drive.google.com/file/d/1Mon0KL4b3D7T_7nDCyveyYJe_-iE4UzD/view?usp=sharing" }
            ]
          },
          hardcore: {
            number: 2,
            title: "Desterium Hardcore",
            images: generateImages('/img/hardcore', 49),
            subtitle: "Servidor MMORPG 2D basado Argentum Online y Tierras del Sur. Modo Hardcore con mejoras.",
            sharedDownloadWith: 'Desterium Facil',
            details: [
              { text: "Ver estadísticas de personajes", link: "/user-list?server=2&servername=Desterium%20Hardcore" },
              { text: "Ver código en GitHub", link: "https://github.com/LautaroMarino/DesteriumGame" }
            ],
            downloads: [
              { label: "Opción 1 - DESCARGA DIRECTA", url: "https://www.desterium.net/file/DesteriumV8.exe" },
              { label: "Opción 2 - vía MEDIAFIRE", url: "https://www.mediafire.com/file/jg3e4qlja0jq3ez/DesteriumV8.exe/file" },
              { label: "Opción 3 - vía MEGA", url: "https://mega.nz/file/HBJUULxS#e3kGmiTh0tp4LrQfqxRB5MozHTGWFcKjzSHwXRtEO9M" },
              { label: "Opción 4 - vía DRIVE", url: "https://drive.google.com/file/d/1Mon0KL4b3D7T_7nDCyveyYJe_-iE4UzD/view?usp=sharing" }
            ]
          }
        });
      } catch (error) {
        console.error('Error al cargar los rates:', error);
      }
    };

    fetchRates();
  }, []);

  // Función para generar imágenes
  const generateImages = (path, count) => {
    let images = [];
    if (!path || count <= 0) return images;

    for (let i = 1; i <= count; i++) {
      images.push({ src: `${path}/${i}.jpg`, title: `Image ${i}` });
    }

    return images;
  };

  // Manejo de selección de juego
  const handleGameSelection = (gameKey) => {
    setSelectedGame(gameKey);

    // Verificar si ya se cargaron las imágenes
    if (!imagesLoaded[gameKey]) {
      setShuffledImages(shuffleArray(gamesData[gameKey].images));
      setImagesLoaded(prev => ({ ...prev, [gameKey]: true })); // Marcar como cargado
    } else {
      // Si ya fueron cargadas, simplemente las mezclamos de nuevo
      setShuffledImages(shuffleArray(gamesData[gameKey].images));
    }

    setShowServerInfo(true);
  };

  // Función para mezclar las imágenes
  const shuffleArray = (array) => {
    let shuffled = array.slice(); // Copia del array original
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Función para abrir el modal de imagen
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal de imagen
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Toggle para descargas
  const toggleDownloads = () => {
    setShowDownloads(!showDownloads);
  };

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false
  };

  return (
    <Provider store={store}>
      <GlobalProvider>
        <Router>
          <div className="App">
            <ResponsiveMenu />
            <div className="content-padding">
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/user" element={<UserPage />} />
                <Route path="/forgot-password" element={<RecoverPage />} />
                <Route path="/reset-password" element={<ChangePasswordPage />} />
                <Route path="/reset-pin" element={<ChangePinPage />} />
                <Route path="/download" element={<DownloadPage />} />
                <Route 
                  path="/user-list" 
                  element={<UserListPage server={gamesData[selectedGame]?.number} serverName={gamesData[selectedGame]?.title} />} 
                />
                <Route path="/" element={
                  <div className="welcome-screen">
                    {!showServerInfo ? (
                      <div className="info-box">
                        <h1 className="medieval-title">Servidores MMORPG</h1>
                        <p className="medieval-subtitle">¡Elige el juego o servidor que más te guste!</p>

                        <div className="game-container">
                          <div className="server-list-container">
                            <ul className="server-list">
                              {Object.keys(gamesData).map(game => (
                                <li key={game}>
                                  <button onClick={() => handleGameSelection(game)} className={selectedGame === game ? 'active' : ''}>
                                    {gamesData[game].title}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* Redes sociales */}
                          <div className="social-icons-container">
                            <a href={discordUrl} target="_blank" rel="noopener noreferrer" title="Discord">
                              <img src={discordIcon} alt="Discord" className="social-icon" />
                            </a>
                            <a href={twitchUrl} target="_blank" rel="noopener noreferrer" title="Twitch">
                              <img src={twitchIcon} alt="Twitch" className="social-icon" />
                            </a>
                            <a href={instagramUrl} target="_blank" rel="noopener noreferrer" title="Instagram">
                              <img src={instagramIcon} alt="Instagram" className="social-icon" />
                            </a>
                            <a href={facebookUrl} target="_blank" rel="noopener noreferrer" title="Facebook">
                              <img src={facebookIcon} alt="Facebook" className="social-icon" />
                            </a>
                            <a href={youtubeUrl} target="_blank" rel="noopener noreferrer" title="Youtube">
                              <img src={youtubeIcon} alt="Youtube" className="social-icon" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="server-info-box">
                        {showServerInfo && selectedGame && (
                          <div>
                             {/* Botón 'Volver' como una X flotante */}
                            <div className="close-button-container">
                              <button onClick={() => setShowServerInfo(false)} className="close-button">
                                ×
                              </button>
                            </div>
                            <h2 className="game-title">{gamesData[selectedGame].title}</h2>
                            <div className="game-info">
                              <span className="badge">{serverRates[gamesData[selectedGame].number]?.exp}</span>
                              <span className="badge">{serverRates[gamesData[selectedGame].number]?.oro}</span>
                              <span className="badge">{serverRates[gamesData[selectedGame].number]?.drop}</span>
                              <span className="badge-reset">{serverRates[gamesData[selectedGame].number]?.reset}</span>
                              <span className="badge-online">Onlines: {serverRates[gamesData[selectedGame].number]?.onlines}</span>
                              <h3 className="game-subtitle">{gamesData[selectedGame].subtitle}</h3>
                            </div>
                          </div>
                        )}

                        <div className="carousel-container">
                          <Slider {...settings}>
                            {shuffledImages.map((image, index) => (
                              <div key={index}>
                                <img 
                                  src={image.src} 
                                  alt={image.title} 
                                  style={{ maxWidth: '100%', cursor: 'pointer' }} 
                                  onClick={() => openImageModal(image)} 
                                />
                              </div>
                            ))}
                          </Slider>
                        </div>

                        {/* Modal para imagen a tamaño completo */}
                        {isModalOpen && (
                          <div className="modal-overlay" onClick={closeModal}>
                            <div className="modal-content">
                              <span className="close-button" onClick={closeModal}>×</span>
                              <img
                                src={selectedImage.src}
                                alt={selectedImage.title}
                                className="modal-image"
                                style={{ maxWidth: '90%', maxHeight: '90%' }}
                              />
                            </div>
                          </div>
                        )}

                        <ul className="game-details">
                          {gamesData[selectedGame].details.map((detail, index) => (
                            <li key={index}>
                              {typeof detail === 'string' ? detail : (
                                <a href={detail.link} target="_blank" rel="noopener noreferrer">{detail.text}</a>
                              )}
                            </li>
                          ))}
                        </ul>

                        {gamesData[selectedGame].sharedDownloadWith && (
                          <p className="shared-download-text">
                            Este servidor comparte descarga con {gamesData[selectedGame].sharedDownloadWith}, descárgalo una vez.
                          </p>
                        )}

                        <div className="download-container">
                          <button className="btn-download" onClick={toggleDownloads}>Descargar</button>
                        </div>

                        {showDownloads && (
                          <div className="downloads-list">
                            <p className="downloads-subtitle">Elige una única Descarga</p>
                            {gamesData[selectedGame].downloads.map((download, index) => (
                              <div key={index}>
                                <a href={download.url} target="_blank" rel="noopener noreferrer" className="download-link">{download.label}</a>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                } />
              </Routes>
            </div>
          </div>
        </Router>
      </GlobalProvider>
    </Provider>
  );
}

export default App;
