import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Tabs, Tab, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import './UserListPage.css'; 
import { useLocation } from 'react-router-dom';

const localeText = {

  noRowsLabel: 'Sin filas',
  noResultsOverlayLabel: 'Sin resultados',
  errorOverlayDefaultLabel: 'Ha ocurrido un error.',
  footerRowSelected: (count) => `${count} fila(s) seleccionada(s)`,
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount} de ${totalCount}`,
  columnMenuLabel: 'Menú',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar Ascendente',
  columnMenuSortDesc: 'Ordenar Descendente',
  footerRowPerPage: 'Filas por página:',
  footerTotalRows: 'Total de filas:',

  MuiTablePagination: {
    labelRowsPerPage: 'Filas por página:',
    labelDisplayedRows: ({ from, to, count }) => `${from}–${to} de ${count}`,
  }
};

const rankingsConfig = [
  { label: 'Retos', key: 'fight' },
  { label: 'Retos Rápidos', key: 'fightFast' },
  { label: 'Eventos', key: 'events' },
  { label: 'Desafíos', key: 'challenge' },
  // Puedes agregar más configuraciones aquí
];

const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedRace, setSelectedRace] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [ranking, setRanking] = useState([]);
  const [mainTab, setMainTab] = useState(0);
  const [rankingTab, setRankingTab] = useState(0);

  // Obtener el valor del parámetro 'server' desde la URL
  const queryParams = new URLSearchParams(location.search);
  const server = queryParams.get('server');
  const serverName = queryParams.get('servername');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!server) {
          console.error("Servidor no seleccionado.");
          setLoading(false);
          return;
        }

        const response = await fetch(`/api/characters?server=${server}`);
        const text = await response.text();
        const data = JSON.parse(text);

        if (data.error) {
          throw new Error(data.error);
        }

        console.log('Datos recibidos:', data);

        const flattenedData = data.map(player => ({
          ...player,
          ...player.stats,
          ...player.attributes
        }));

        setUsers(flattenedData);
        setFilteredUsers(flattenedData);

        const rankingData = data.map(player => ({
          username: player.username,
          fight_played: player.ranking?.fight_played || 0,
          fight_win: player.ranking?.fight_win || 0,
          fight_score: player.ranking?.fight_score || 0,
          fightFast_played: player.ranking?.fightFast_played || 0,
          fightFast_win: player.ranking?.fightFast_win || 0,
          fightFast_score: player.ranking?.fightFast_score || 0,
          events_played: player.ranking?.events_played || 0,
          events_win: player.ranking?.events_win || 0,
          events_score: player.ranking?.events_score || 0,
          challenge_played: player.ranking?.challenge_played || 0,
          challenge_win: player.ranking?.challenge_win || 0,
          challenge_score: player.ranking?.challenge_score || 0,
        }));

        setRanking(rankingData);
      } catch (error) {
        console.error('Error al obtener los personajes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [server]);

  useEffect(() => {
    // Filtrar usuarios por nombre, clase y raza
    const results = users.filter(user =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedClass === '' || user.clase === selectedClass) &&
      (selectedRace === '' || user.raza === selectedRace)
    );
    setFilteredUsers(results);
  }, [searchTerm, selectedClass, selectedRace, users]);

  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };

  const handleRankingTabChange = (event, newValue) => {
    setRankingTab(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleRaceChange = (event) => {
    setSelectedRace(event.target.value);
  };

  return (
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
      <Tabs
        value={mainTab}
        onChange={handleMainTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        sx={{
          '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
          '& .MuiTab-root': {
            color: '#FFA726', // Texto naranja
            fontFamily: 'Cinzel, serif',
            fontSize: '16px',
            '&.Mui-selected': {
              color: '#FFEB3B', // Texto amarillo para la pestaña seleccionada
            },
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#FFEB3B', // Indicador amarillo
          },
        }}
      >
        <Tab label="Estadísticas" />
        <Tab label="Ranking" />
      </Tabs>

      {mainTab === 1 && (
        <Tabs
          value={rankingTab}
          onChange={handleRankingTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
            '& .MuiTab-root': {
              color: '#FFA726', // Texto naranja
              fontFamily: 'Cinzel, serif',
              fontSize: '16px',
              '&.Mui-selected': {
                color: '#FFEB3B', // Texto amarillo para la pestaña seleccionada
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#FFEB3B', // Indicador amarillo
            },
          }}
        >
          {rankingsConfig.map((ranking, index) => (
            <Tab key={index} label={ranking.label} />
          ))}
        </Tabs>
      )}

      <Box className="info-box" sx={{ textAlign: 'center', marginBottom: '20px' }}>
        {mainTab === 0 && (
          <>
            <h1 className="medieval-title" style={{ textShadow: '2px 2px 4px #000000', color: '#FFEB3B' }}>
              Estadísticas
            </h1>
            <p className="medieval-subtitle" style={{ textShadow: '1px 1px 2px #000000', color: '#FFA726' }}>
              Personajes registrados en el servidor '{serverName}'
            </p>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
              <TextField
                label="Buscar por Nombre"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ width: '250px', backgroundColor: '#333', color: '#FFEB3B' }}
                InputLabelProps={{
                  style: { color: '#FFEB3B' },
                }}
                InputProps={{
                  style: { color: '#FFEB3B' },
                }}
              />
              <FormControl sx={{ width: '150px', backgroundColor: '#333' }}>
                <InputLabel sx={{ color: '#FFEB3B' }}>Clase</InputLabel>
                <Select
                  value={selectedClass}
                  onChange={handleClassChange}
                  sx={{ color: '#FFEB3B' }}
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="Mago">Mago</MenuItem>
                  <MenuItem value="Clerigo">Clerigo</MenuItem>
                  <MenuItem value="Guerrero">Guerrero</MenuItem>
                  <MenuItem value="Asesino">Asesino</MenuItem>
                  <MenuItem value="Ladron">Ladron</MenuItem>
                  <MenuItem value="Bardo">Bardo</MenuItem>
                  <MenuItem value="Druida">Druida</MenuItem>
                  <MenuItem value="Paladin">Paladin</MenuItem>
                  <MenuItem value="Cazador">Cazador</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: '150px', backgroundColor: '#333' }}>
                <InputLabel sx={{ color: '#FFEB3B' }}>Raza</InputLabel>
                <Select
                  value={selectedRace}
                  onChange={handleRaceChange}
                  sx={{ color: '#FFEB3B' }}
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="Humano">Humano</MenuItem>
                  <MenuItem value="Elfo">Elfo</MenuItem>
                  <MenuItem value="Elfo Oscuro">Elfo Oscuro</MenuItem>
                  <MenuItem value="Gnomo">Gnomo</MenuItem>
                  <MenuItem value="Enano">Enano</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        )}
        {mainTab === 1 && (
          <>
            <h1 className="medieval-title" style={{ textShadow: '2px 2px 4px #000000', color: '#FFEB3B' }}>
              {rankingsConfig[rankingTab].label}
            </h1>
            <p className="medieval-subtitle" style={{ textShadow: '1px 1px 2px #000000', color: '#FFA726' }}>
              Información sobre el ranking de {rankingsConfig[rankingTab].label.toLowerCase()}
            </p>
            <ul style={{ color: '#FFEB3B', textAlign: 'left', marginTop: '10px' }}>
              <li>El top se basa entre las partidas ganadas/jugadas y genera el score final.</li>
              <li>El ranking se actualiza cada vez que se guarda el personaje.</li>
              <li>Participa en más retos para mejorar tu posición.</li>
              {/* Agrega más elementos a la lista según sea necesario */}
            </ul>
          </>
        )}
      </Box>

      {loading ? (
        <div style={{ color: '#FFEB3B' }}>Cargando datos...</div>
      ) : (
        <>
          {mainTab === 0 && (
            <DataGrid
              rows={filteredUsers.map(user => {
                const nivel = user.elv || 1; // Si el nivel es 0, mostrar 1
                const porcentaje = user.elu ? `${Math.round((user.exp / user.elu) * 100)}%` : '-'; // Si Elu es 0, mostrar "Máximo"
                return {
                  ...user,
                  nivel,
                  experiencia: porcentaje
                };
              })}
              columns={[
                { field: 'username', headerAlign: 'center', headerName: 'Nombre', width: 150 },
                { field: 'clase', headerAlign: 'center', headerName: 'Clase', width: 150 },
                { field: 'raza', headerAlign: 'center', headerName: 'Raza', width: 150 },
                { field: 'nivel', headerAlign: 'center', headerName: 'Nivel', width: 100 },
                { field: 'experiencia', headerAlign: 'center', headerName: 'Experiencia', width: 150 },
                { field: 'gld', headerAlign: 'center', headerName: 'Oro', width: 150 },
                { field: 'eldhir', headerAlign: 'center', headerName: 'Dsp', width: 150 },
                { field: 'max_hp', headerAlign: 'center', headerName: 'Vida', width: 100 },
                { field: 'ups', headerAlign: 'center', headerName: 'Ups', width: 100 }
              ]}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              hideFooterSelectedRowCount
              getRowId={(row) => row.username}
              localeText={localeText}
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro para todo el DataGrid
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#3E2723', // Tono marrón oscuro en encabezados
                  color: '#E1C699', // Texto dorado suave en encabezados
                  fontFamily: 'Cinzel, serif',
                  fontSize: '16px',
                  borderBottom: '1px solid #E1C699',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#3E2723', // Fondo oscuro en los encabezados
                  color: '#E1C699', // Texto dorado suave
                  display: 'flex',
                  justifyContent: 'center', // Centrar horizontalmente
                  alignItems: 'center', // Centrar verticalmente
                  textAlign: 'center', // Refuerzo para centrar texto
                  padding: '0px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  width: '100%', // Ocupar todo el ancho disponible
                  display: 'flex',
                  justifyContent: 'center', // Centrar horizontalmente
                  alignItems: 'center', // Centrar verticalmente
                  textAlign: 'center', // Texto centrado
                  fontWeight: 'bold', // Mantener la negrita
                },
                '& .MuiDataGrid-sortIcon': {
                  position: 'absolute',
                  right: '8px', // Align the sort icon to the right
                  color: '#E1C699', // Match the icon color to the header text color
                },
                '& .MuiDataGrid-cell': {
                  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fondo oscuro en las celdas
                  color: '#E1C699', // Texto dorado
                  borderBottom: 'none', // Sin bordes internos en las celdas
                  fontFamily: 'Cinzel, serif',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                '& .MuiDataGrid-row': {
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro en las filas
                  borderBottom: '2px solid #E1C699', // Borde dorado suave entre filas
                  '&:hover': {
                    backgroundColor: 'rgba(45, 41, 42, 1)', // Fondo más oscuro al hacer hover
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(255, 215, 0, 0.2) !important', // Fondo dorado suave para filas seleccionadas
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'rgba(255, 215, 0, 0.4) !important', // Hover para filas seleccionadas
                  },
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: '#3E2723', // Pie de página con tono marrón oscuro
                  color: '#E1C699',
                  borderTop: '1px solid #E1C699',
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo semitransparente en el área de scroll
                },
                '& .MuiDataGrid-overlay': {
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro para superposiciones
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none', // Oculta los separadores de columnas
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  color: '#E1C699', // Ajusta el color de los botones de acción de las columnas
                },
                '& ::-webkit-scrollbar': {
                  width: '10px',
                  height: '10px',
                },
                '& ::-webkit-scrollbar-thumb': {
                  backgroundColor: '#E1C699', // Scroll dorado suave
                  borderRadius: '5px',
                },
                '& ::-webkit-scrollbar-track': {
                  backgroundColor: '#222', // Fondo oscuro del scroll
                },
              }}
            />
          )}

          {mainTab === 1 && (
            <DataGrid
              rows={ranking}
              columns={[
                { field: 'username', headerName: 'Nombre', width: 150, headerAlign: 'center' },
                { field: `${rankingsConfig[rankingTab].key}_win`, headerName: 'Ganados', width: 150, headerAlign: 'center' },
                { field: `${rankingsConfig[rankingTab].key}_played`, headerName: 'Jugados', width: 150, headerAlign: 'center' },
                { field: `${rankingsConfig[rankingTab].key}_score`, headerName: 'Score', width: 150, headerAlign: 'center' }
              ]}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              hideFooterSelectedRowCount
              getRowId={(row) => row.username}
              localeText={localeText}
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro para todo el DataGrid
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#3E2723', // Tono marrón oscuro en encabezados
                  color: '#E1C699', // Texto dorado suave en encabezados
                  fontFamily: 'Cinzel, serif',
                  fontSize: '16px',
                  borderBottom: '1px solid #E1C699',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#3E2723', // Fondo oscuro en los encabezados
                  color: '#E1C699', // Texto dorado suave
                  display: 'flex',
                  justifyContent: 'center', // Centrar horizontalmente
                  alignItems: 'center', // Centrar verticalmente
                  textAlign: 'center', // Refuerzo para centrar texto
                  padding: '0px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  width: '100%', // Ocupar todo el ancho disponible
                  display: 'flex',
                  justifyContent: 'center', // Centrar horizontalmente
                  alignItems: 'center', // Centrar verticalmente
                  textAlign: 'center', // Texto centrado
                  fontWeight: 'bold', // Mantener la negrita
                },
                '& .MuiDataGrid-sortIcon': {
                  position: 'absolute',
                  right: '8px', // Align the sort icon to the right
                  color: '#E1C699', // Match the icon color to the header text color
                },
                '& .MuiDataGrid-cell': {
                  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fondo oscuro en las celdas
                  color: '#E1C699', // Texto dorado
                  borderBottom: 'none', // Sin bordes internos en las celdas
                  fontFamily: 'Cinzel, serif',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                '& .MuiDataGrid-row': {
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro en las filas
                  borderBottom: '2px solid #E1C699', // Borde dorado suave entre filas
                  '&:hover': {
                    backgroundColor: 'rgba(45, 41, 42, 1)', // Fondo más oscuro al hacer hover
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(255, 215, 0, 0.2) !important', // Fondo dorado suave para filas seleccionadas
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'rgba(255, 215, 0, 0.4) !important', // Hover para filas seleccionadas
                  },
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: '#3E2723', // Pie de página con tono marrón oscuro
                  color: '#E1C699',
                  borderTop: '1px solid #E1C699',
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo semitransparente en el área de scroll
                },
                '& .MuiDataGrid-overlay': {
                  backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro para superposiciones
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none', // Oculta los separadores de columnas
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  color: '#E1C699', // Ajusta el color de los botones de acción de las columnas
                },
                '& ::-webkit-scrollbar': {
                  width: '10px',
                  height: '10px',
                },
                '& ::-webkit-scrollbar-thumb': {
                  backgroundColor: '#E1C699', // Scroll dorado suave
                  borderRadius: '5px',
                },
                '& ::-webkit-scrollbar-track': {
                  backgroundColor: '#222', // Fondo oscuro del scroll
                },
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UserListPage;
